<template>
    <div id="container">
        <div id="contents">
			<div class="contents_body">
				<div class="summary-info">
					<div class="tit"><span>배치 실행 결과</span>
						<div style="float:right; margin-top:-15px;">
							<span class="btn moreview" @click="newStart">새로실행</span>
						</div>
					</div>
				</div>						
				<div class="profile">
					<div class="profile-info">
					     <div class="name-info">
							<span class="pretxt1">{{data.jobName}}</span>
							<span class="pretxt2">
								<span >{{ data.lastUpdated }}</span>
								<span> / {{ data.status }}<span v-if="data.status != data.exitCode"> ({{ data.exitCode }})</span></span>
							</span>
						</div>
						<div class="profile-board">
							<table class="profile-type">
								<colgroup>
									<col width="150">
									<col width="400">
									<col width="150">
									<col width="*">
								</colgroup>
								<tbody>
									<tr>
										<th>Instance ID</th>
										<td>{{ data.jobInstanceId }}</td>
										<th>실행 ID</th>
										<td>{{ data.jobExecutionId }}</td>
									</tr>
									<tr>
										<th>시작시간</th>
										<td>{{ data.startTime }}</td>
										<th>종료시간</th>
										<td>{{ data.endTime }}</td>
									</tr>
									<tr>
										<th>종료메시지</th>
										<td colspan="3">{{ data.exitMessage }}</td>
									</tr>											
								</tbody>
							</table>
						</div>
					</div>
				</div>
			  	<div class="Board" style="width: 45.5%;margin-right: 0.5%;">
		  			<h3>실행 파라미터</h3>
					<table class="Board_type1">
						<colgroup>
							<col width="100"/>
							<col width="*"/>
							<col width="*"/>
						</colgroup>
						<thead>
							<tr>
								<th>No</th>
								<th>Key</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(param, index) in data.paramList" :key="param.key">
								<td>{{ index + 1 }}</td>
								<td>{{ param.key }}</td>
								<td>{{ param.value }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			  	<div class="Board" style="width:53.5%; margin-left:0.5%;">
		  			<h3>결과 Context</h3>
					<table class="Board_type1">
						<colgroup>
							<col width="100"/>
							<col width="*"/>
							<col width="*"/>
						</colgroup>
						<thead>
							<tr>
								<th>No</th>
								<th>Key</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(context, index) in data.contextList" :key="context.key">
								<td>{{ index + 1 }}</td>
								<td>{{ context.key }}</td>
								<td>{{ context.value }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				
			  	<div class="Board">
	  				<h3>Step 실행 목록</h3>
					<table class="Board_type1">
						<colgroup>
							<col width="50"/><!-- No -->
							<col width="200"/><!-- Step 이름 -->
							<col width="85"/><!-- 시작시간 -->
							<col width="85"/><!-- 종료시간 -->
							<col width="90"/><!-- 상태 -->
							<col width="200"/><!-- 종료메시지 -->
							<col width="*"/><!-- CONTEXT -->
						</colgroup>
						<thead>
							<tr>
								<th>No</th>
								<th>Step 이름</th>
								<th>시작시간</th>
								<th>종료시간</th>
								<th>상태</th>
								<th>종료메시지</th>
								<th>결과 CONTEXT</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(step, index) in data.list" :key="step.stepName"   :class="{ red : step.status != 'COMPLETED'}">
								<td>{{ index + 1 }}</td>
								<td>{{ step.stepName }}</td>
								<td>{{ step.startTime }}</td>
								<td>{{ step.endTime }}</td>
								<td>{{ step.status }}<span v-if="step.status != step.exitCode"> ({{ step.exitCode }})</span></td>
								<td :title="step.exitMessage">{{ step.exitMessage }}</td>
								<td class="head">
                                    <div v-for="context in step.contextList" :key="context.key">
                                        <span>{{ context.key }}</span> : <span>{{ context.value }}</span>
                                    </div>
                                </td>
							</tr>
						</tbody>
					</table>
				</div>
					
				<!--버튼영역-->
				<div class="BtnArea">
					<div class="Btninner">
						<span class="btn list" @click="btnList">목록</span>
					</div>
				</div>
				<!--//버튼영역-->
			</div><!--//contents_body-->
        </div>
        <!--//contents-->
    </div>
    <!--//container-->
</template>
<script>
    export default {
        data() {
            return {				
				data : {},
            }
        },
        beforeCreate() { // console.log('beforeCreate');
        },
        created() { // console.log('created');
        },
        beforeMount() { // console.log('beforeMount');
        },
        mounted() { // console.log('mounted');
            this.getDetail();
        },
        beforeUpdate() { // console.log('beforeUpdate');
        },
        updated() { // console.log('update');
        },
        beforeDestroy() { // console.log('beforeDestroy');
        },
        destroyed() { // console.log('destroyed');
        },
        methods: {
            getDetail() {

                var param = {
                    jobExecutionId : this.$route.params.jobExecutionId
                };
                
                this.$.httpPost('/api/bat/adm/getBatchJob', param)
                    .then(res => {
                        //console.log(res.data);
						this.data = res.data;

                        // paramJson 파싱
                        this.parsingParamJson();
                    })
                    .catch(err => {
                        // console.log('=============================');
                        // console.log(err);
                        // console.log(err.response);
                        // console.log('=============================');
                        alert(err.response.data.error_description);
                    });
            },
        
            btnList() {
                this.$router.push({name:'ADMBAT01M01'});
            },

            newStart() {

                var param = {
                    jobName : this.data.jobName
                };
                
                this.$.httpPost('/api/bat/adm/runJob', param)
                    .then(() => {
                        // console.log(res.data);						
                        this.btnList();
                    })
                    .catch(err => {
                        // console.log('=============================');
                        // console.log(err);
                        // console.log(err.response);
                        // console.log('=============================');
                        alert(err.response.data.error_description);
                    });
            },

            // paramJson 파싱
            parsingParamJson() {
                
                if(this.data.paramJson == undefined || this.data.paramJson == '') {
                    return;
                }

                var json = JSON.parse(this.data.paramJson);

                var list = [];
                for(var key in json){
                    list.push({
                        key   : key,
                        value : json[key]
                    });
                }

                this.data.paramList = list;

            },            
        }
    }
</script>